<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
      wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
    </p>
    <br /><br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper">
          <b-input-group size="sm">
            <b-form-input v-model="filter" placeholder="Search here...">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <button
        type="button"
        class="btn mulai btn-secondary font-bold"
        @click="gotoBuatList"
      >
        <fa-icon icon="edit" class="my-icon" /> &nbsp; BUAT PLAYLIST
      </button>
    </div>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template slot="no" slot-scope="row">
        {{ row.value }}
      </template>

      <template slot="name" slot-scope="row">
        {{ row.value }}
      </template>

      <template slot="jenjang" slot-scope="row">
        {{ row.value }}
      </template>

      <template slot="videosCount" slot-scope="row">
        {{ row.value }}
      </template>

      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
        >
          <fa-icon icon="pencil-alt" class="my-icon" /> &nbsp;Edit
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
        >
          <fa-icon icon="trash-alt" class="my-icon" /> &nbsp;Delete
        </b-button>

        <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-if="row.item.published"
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="minus-circle" class="my-icon" /> &nbsp;Unpublish
        </b-button>

        <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-else
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="circle" class="my-icon" /> &nbsp;Publish
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          no: 1,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: false
        },
        {
          no: 2,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        },
        {
          no: 3,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: false
        },
        {
          no: 4,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        },
        {
          no: 5,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: false
        },
        {
          no: 6,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        },
        {
          no: 7,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        },
        {
          no: 8,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        },
        {
          no: 9,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        },
        {
          no: 10,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        },
        {
          no: 11,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        },
        {
          no: 12,
          name: "Belajar Berhitung Cepat",
          jenjang: "SMA",
          videosCount: "12 Video",
          published: true
        }
      ],

      fields: [
        { key: "no", label: "No" },
        {
          key: "name",
          label: "Judul Playlist",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "jenjang",
          label: "Jenjang",
          sortable: true,
          class: "text-center"
        },
        { key: "videosCount", label: "Jumlah Video" },
        { key: "actions", label: "Action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null
    };
  },

  mounted() {
    this.totalRows = this.items.length;
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoBuatList() {
      this.$router.push("/dashboard/video-bimbel/buat-playlist");
    }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}
</style>
